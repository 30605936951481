<template>
    <div>
        <base-header class="pb-6">
            <div class="row align-items-center py-4">
                <div class="col-lg-6 col-7">
                    <h6 class="h2 text-white d-inline-block mb-0"></h6>
                </div>      
            </div>
        </base-header>
        <div class="container-fluid mt--6">
            <div class="card mb-4">
                <div class="card-header mt--4 mb--4">
                    <div class="row">
                        <div class="col">
                            <h3 class="mb-0">{{ tt('master_data_synchron') }}</h3>
                        </div>
                        <div class="col-6">
                            <base-input input-classes="form-control-sm text-center" v-model="sapConnection.search" :placeholder="tt('search_master_data_synchron')"/>
                        </div>
                        <div class="col text-right">
                            <base-button size="sm" type="default" @click="create">{{ tt('add_new') }}</base-button>
                        </div>
                    </div>
                </div>
                <div>
                    <el-table height="350px" class="table-responsive table-flush" header-row-class-name="thead-light" :data="table.data">
                        <el-table-column :label="tt('table')" :prop="tt('table')" min-width="170px" sortable>
                            <template v-slot="{row}">
                                {{row.table}}
                            </template>
                        </el-table-column>
                        <el-table-column :label="tt('function')" :prop="tt('function')" min-width="170px" sortable>
                            <template v-slot="{row}">
                                {{row.function}}
                            </template>
                        </el-table-column>
                        <el-table-column :label="tt('input')" :prop="tt('input')" min-width="700px" sortable>
                            <template v-slot="{row}">
                                {{row.input}}
                            </template>
                        </el-table-column>
                        <el-table-column :label="tt('output')" :prop="tt('output')" min-width="170px" sortable>
                            <template v-slot="{row}">
                                {{row.output}}
                            </template>
                        </el-table-column>
                        <el-table-column prop="action" width="100">
                            <template v-slot="{row}">
                                <el-dropdown trigger="click" class="dropdown" @command="handleTableAction" :hide-on-click="true">
                                    <span class="btn btn-sm btn-icon-only text-light">
                                        <i class="fas fa-ellipsis-v mt-2"></i>
                                    </span>
                                    <el-dropdown-menu class="dropdown-menu dropdown-menu-arrow show" slot="dropdown">
                                        <el-dropdown-item :command="{action:'synchron', data:row.id}">{{ tt('synchron') }}</el-dropdown-item>                                        
                                        <el-dropdown-item :command="{action:'edit', data:row.id}">{{ tt('edit') }}</el-dropdown-item>
                                        <el-dropdown-item :command="{action:'remove', data:row.id}">{{ tt('delete') }}</el-dropdown-item>                                        
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </div>
        <modal :show.sync="form.show">
            <template slot="header">
                <h5 class="modal-title">{{form.title}}</h5>
            </template>
            <div>
                <label class="form-control-label">{{ tt('table') }} <span class="text-danger">*</span></label>
                <base-input :name="tt('table')" :placeholder="tt('table')" v-model="sapConnection.table" rules="required"></base-input>

                <label class="form-control-label">{{ tt('function') }} <span class="text-danger">*</span></label>
                <base-input :name="tt('function')" :placeholder="tt('function')" v-model="sapConnection.function" rules="required"></base-input>

                <label class="form-control-label">{{ tt('input') }}</label>
                <base-input :name="tt('input')" :placeholder="tt('input')" v-model="sapConnection.input"></base-input>

                <label class="form-control-label">{{ tt('output') }} <span class="text-danger">*</span></label>
                <base-input :name="tt('output')" :placeholder="tt('output')" v-model="sapConnection.output" rules="required"></base-input>
            </div>
            <template slot="footer">
                <base-button type="secondary" @click="form.show = false">{{ tt('reset') }}</base-button>
                <base-button type="primary">
                    <span v-if="form.add">{{ tt('add') }}</span>
                    <span v-else>{{ tt('edit') }}</span>
                </base-button>
            </template>
        </modal>
    </div>
</template>
<script>
    export default {        
        data() {
            return {                
                form: {
                    add: true,
                    title: this.tt('add_master_data_synchron'),
                    show: false
                }, 
                table: {
                    data: [
                        {
                            id: 1,
                            table: "company_code", 
                            function: "ZCOMPANY_GET",
                            input: `a:1:{s:7:"R_BUKRS";a:1:{i:0;a:3:{s:4:"SIGN";s:1:"I";s:6:"OPTION";s:2:"CP";s:3:"LOW";s:2:"1*";}}}`,
                            output: "T_DATA",
                        },
                        {
                            id: 2,
                            table: "plant_code", 
                            function: "ZPLANT_GET",
                            input: `a:1:{s:7:"R_WERKS";a:1:{i:0;a:3:{s:4:"SIGN";s:1:"I";s:6:"OPTION";s:2:"CP";s:3:"LOW";s:2:"1*";}}}`,
                            output: "T_DATA",
                        },
                        {
                            id: 3,
                            table: "location_code", 
                            function: "ZSTORAGE_LOCATION_GET",
                            input: `a:1:{s:7:"R_WERKS";a:1:{i:0;a:3:{s:4:"SIGN";s:1:"I";s:6:"OPTION";s:2:"CP";s:3:"LOW";s:2:"1*";}}}`,
                            output: "T_DATA",
                        },
                    ]
                },        
                sapConnection: {
                    
                }  
            }
        },
        methods: {
            handleTableAction(command) {
                switch (command.action) {
                    case 'synchron':
                            this.synchron(command.data);
                        break;
                    case 'edit':
                            this.edit(command.data);
                        break;
                    case 'remove':
                            this.remove(command.data);
                        break;
                    default:
                        break;
                }
            },
            create() {
                this.form.add = true;
                this.form.title = this.tt('add_master_data_synchron');
                this.form.show = true;
            },
            synchron(id) {
                this.confirmDialog(this.tt('confirm_synchron')).then((result) => {
                    if (result.value) {
                        this.alertDialog('success', this.tt('success_synchron'));
                    } else {
                        this.alertDialog('error', this.tt('error_synchron'));
                    }
                })
            },
            edit(id) {
                this.form.add = false;
                this.form.title = this.tt('edit_master_data_synchron');
                this.form.show = true;
            },
            remove(id) {
                this.confirmDialog(this.tt('confirm_delete')).then((result) => {
                    if (result.value) {
                        this.alertDialog('success', this.tt('success_delete'));
                    } else {
                        this.alertDialog('error', this.tt('error_delete'));
                    }
                })
            },
        }   
    };
</script>
<style></style>
